import "@fontsource/roboto";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import type * as Button from "antd/es/button/style";
import type * as Card from "antd/es/card/style";
import type * as Forms from "antd/es/form/style";
import type * as Layout from "antd/es/layout/style";
import type * as Modal from "antd/es/modal/style";
import type * as Table from "antd/es/table/style";
import type * as Tabs from "antd/es/tabs/style";
import type { AliasToken, SeedToken } from "antd/es/theme/internal";
import type { ColorMapToken, ColorNeutralMapToken, FontMapToken, MapToken } from "antd/es/theme/interface";
import type { ThemeConfig } from "antd";

// -------------------------------------------------------------------
// Seed Token
// -------------------------------------------------------------------

const ANTDSeedToken: Partial<SeedToken> = {
    borderRadius: 4,
    colorBgBase: "#fff",
    colorError: "#b21c1a",
    colorInfo: "#1a4a93",
    colorLink: "#2662C8",
    colorPrimary: "#91c73d",
    colorSuccess: "#91c73d",
    colorTextBase: "#333",
    colorWarning: "#f0a51f",
    // controlHeight: 32 (ANTD Default)
    fontFamily: `Roboto, "Work Sans", sans-serif`,
    // fontFamilyCode: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace (ANTD Default)
    fontSize: 14,
    // lineType: solid (ANTD Default)
    // lineWidth: 1 (ANTD Default)
    // motion: true (ANTD Default)
    // opacityImage: 1 (ANTD Default)
    // sizePopupArrow: 16 (ANTD Default)
    // sizeStep: 4 (ANTD Default)
    // sizeUnit: 4 (ANTD Default)
    // wireframe: false (ANTD Default)
    // zIndexBase: 0 (ANTD Default)
    // zIndexPopupBase: 1000 (ANTD Default)
};

// -------------------------------------------------------------------
// Map Token
// -------------------------------------------------------------------

const ANTDColorNeutralMapToken: Partial<ColorNeutralMapToken> = {
    // -------- Text -----------
    colorText: "#333333",
    colorTextSecondary: "#5d5d5d",
    colorTextTertiary: "#868686",
    // colorTextQuaternary: "",
    // -------- Borders -----------
    colorBorder: "#dadada",
    colorBorderSecondary: "#a9a9a9",
    // -------- Backgrounds -----------
    // colorBgLayout: "",
    colorBgContainer: "#fff",
    // colorBgElevated: "",
    // colorBgSpotlight: "",
    // colorBgBlur: "",
};

const ANTDColorPrimaryMapToken: Partial<ColorMapToken> = {
    colorPrimaryBg: "#e7f2d4",
    colorPrimaryBgHover: "#91C73D",
    // colorPrimaryBorder: "";
    // colorPrimaryBorderHover: "";
    // colorPrimaryHover: "";
    // colorPrimaryActive: "";
    // colorPrimaryTextHover: "";
    // colorPrimaryText: "";
    // colorPrimaryTextActive: "";
};

const ANTDColorSuccessMapToken: Partial<ColorMapToken> = {
    colorSuccessBg: "#FFFFFF",
    // colorSuccessBgHover: "";
    // colorSuccessBorder: "";
    // colorSuccessBorderHover: "";
    // colorSuccessHover: "";
    // colorSuccessActive: "";
    // colorSuccessTextHover: "";
    // colorSuccessText: "";
    // colorSuccessTextActive: "";
};

const ANTDColorWarningMapToken: Partial<ColorMapToken> = {
    colorWarningBg: "#FFFFFF",
    // colorWarningBgHover: "";
    // colorWarningBorder: "";
    // colorWarningBorderHover: "";
    // colorWarningHover: "";
    // colorWarningActive: "";
    // colorWarningTextHover: "";
    // colorWarningText: "";
    // colorWarningTextActive: "";
};

const ANTDColorInfoMapToken: Partial<ColorMapToken> = {
    colorInfoBg: "#FFFFFF",
    // colorInfoBgHover: "";
    // colorInfoBorder: "";
    // colorInfoBorderHover: "";
    // colorInfoHover: "";
    // colorInfoActive: "";
    // colorInfoTextHover: "";
    // colorInfoText: "";
    // colorInfoTextActive: "";
};

const ANTDColorErrorMapToken: Partial<ColorMapToken> = {
    colorErrorBg: "#FFFFFF",
    // colorErrorBgHover: "",
    // colorErrorBorder: "",
    // colorErrorBorderHover: "",
    // colorErrorHover: "",
    // colorErrorActive: "",
    // colorErrorTextHover: "",
    // colorErrorText: "",
    // colorErrorTextActive: "",
};

const ANTDColorLinkMapToken: Partial<ColorMapToken> = {
    colorLinkHover: "#0C367D",
    colorLinkActive: "#0C367D",
};

const ANTDFontMapToken: Partial<FontMapToken> = {
    // fontSizeSM: 0,
    // fontSize: 0,
    // fontSizeLG: 0,
    // fontSizeXL: 0,
    fontSizeHeading1: 22,
    fontSizeHeading2: 20,
    fontSizeHeading3: 18,
    fontSizeHeading4: 16,
    fontSizeHeading5: 16,
    // lineHeight: 0,
    // lineHeightLG: 0,
    // lineHeightSM: 0,
    // lineHeightHeading1: 0,
    // lineHeightHeading2: 0,
    // lineHeightHeading3: 0,
    // lineHeightHeading4: 0,
    // lineHeightHeading5: 0,
};

const ANTDMapToken: Partial<MapToken> = {
    ...ANTDColorNeutralMapToken,
    ...ANTDColorPrimaryMapToken,
    ...ANTDColorSuccessMapToken,
    ...ANTDColorWarningMapToken,
    ...ANTDColorInfoMapToken,
    ...ANTDColorErrorMapToken,
    ...ANTDColorLinkMapToken,
    ...ANTDFontMapToken,
};

// -------------------------------------------------------------------
// Alias Token
// -------------------------------------------------------------------

export const ANTDToken: Partial<AliasToken> = {
    ...ANTDSeedToken,
    ...ANTDMapToken,
    // -------- Additional Alias Properties -----------
    // - Disabled -----------
    colorTextDisabled: "#bfbfbf",
    colorBgContainerDisabled: "#f5f5f5",
    // - Selecting -----------
    colorHighlight: "#b21c1a",
};

// -------------------------------------------------------------------
// Components
// -------------------------------------------------------------------

const ANTDForm: Partial<Forms.ComponentToken> = {
    // Move Margin Responsibility to Label So Last Item Can Be Flush In Form
    itemMarginBottom: (ANTDSeedToken.fontSize ?? 14) * 0.75,
    // Bring Labels Closer To Input
    verticalLabelPadding: "0 0 0.25rem",
};

const ANTDTables: Partial<Table.ComponentToken> = {
    bodySortBg: "#fff",
    cellPaddingBlock: 14,
    // Selected Row
    rowHoverBg: "#fbfff5",
    rowSelectedBg: "#e7f2d4",
    rowSelectedHoverBg: "#e7f2d4",
    // Want to only make tables have primary border vs secondary
    headerSplitColor: ANTDToken.colorBorder,
    borderColor: ANTDToken.colorBorder,
};

const ANTDTabs: Partial<Tabs.ComponentToken> = {
    itemHoverColor: "#333",
    itemActiveColor: "#333",
    itemSelectedColor: "#333",
    itemColor: "87878a",
    inkBarColor: "#333",
    // Shift Margin Responsibility To Content In Tabs to make background color more continuous
    horizontalMargin: "0",
};

const ANTDModal: Partial<Modal.ComponentToken> = {
    // This makes it so header and footer is same color as bg
    // it's an awkward box otherwise
    headerBg: "#FFFFFF",
    contentBg: "#fafcff",
    footerBg: "#FFFFFF",
};

const ANTDCard: Partial<Card.ComponentToken> & Partial<AliasToken> = {
    borderRadiusLG: 7,
};

const ANTDButton: Partial<Button.ComponentToken> = {
    fontWeight: 500,
};

const ANTDLayout: Partial<Layout.ComponentToken> = {
    headerBg: "white",
    bodyBg: "transparent",
    footerBg: "transparent",
    headerPadding: "16px",
};

// -------------------------------------------------------------------
// Theme Export
// -------------------------------------------------------------------

export const VyneTheme: ThemeConfig = {
    token: ANTDToken,
    components: {
        Button: ANTDButton,
        Card: ANTDCard,
        Modal: ANTDModal,
        Table: ANTDTables,
        Tabs: ANTDTabs,
        Layout: ANTDLayout,
        Form: ANTDForm,
    },
};

// -------------------------------------------------------------------
// Helpers
// -------------------------------------------------------------------

// This will print out an exportable json for you to use with ANTD Theme Editor
// Link: https://ant.design/theme-editor
// console.log(JSON.stringify(VyneTheme));

// This will help you find any hard coded colors in VSCode
// Regex: #[0-9A-Fa-f]{6}|rgb\(\d{1,3}, \d{1,3}, \d{1,3}\)|hsl\(\d{1,3}, \d{1,3}%, \d{1,3}%\)
