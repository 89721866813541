import * as ignoreCase from "utils/ignoreCase";
import { CreditCardOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import amex from "assets/amex.png";
import discover from "assets/discover.png";
import mastercard from "assets/mastercard.png";
import visa from "assets/visa.png";
import type { CardOnFile } from "api/responses";

interface CardProps {
    card: Omit<CardOnFile, "id">;
    verbose?: boolean;
}

interface PlaceholderCardProps {
    placeholder: true;
}

export type CardSummaryProps = CardProps | PlaceholderCardProps;

function getIcon(cardBrand: string) {
    if (ignoreCase.equals(cardBrand, "amex") || ignoreCase.equals(cardBrand, "american express")) {
        return amex;
    } else if (ignoreCase.equals(cardBrand, "discover")) {
        return discover;
    } else if (ignoreCase.equals(cardBrand, "mastercard")) {
        return mastercard;
    } else if (ignoreCase.equals(cardBrand, "visa")) {
        return visa;
    }
}

const CardIcon = ({ cardBrand }: { cardBrand: string }) => {
    const icon = getIcon(cardBrand);

    // If the card brand icon is not supported, return generic icon
    return icon ? <img src={icon} alt={cardBrand} height={20} /> : <CreditCardOutlined alt={cardBrand} />;
};

/** Display card information including an icon for the card brand. */
export const CardSummary = (props: CardSummaryProps) => (
    <Flex align="center" gap="0.25rem 1rem" className="card-summary-container">
        {"placeholder" in props ? <SelectACardText /> : <CardData card={props.card} verbose={props.verbose} />}
    </Flex>
);

const SelectACardText = () => (
    <>
        <CreditCardOutlined />
        <p>Select A Card</p>
    </>
);

const CardData = ({ card, verbose }: CardProps) => (
    <>
        <CardIcon cardBrand={card.cardBrand} />
        <p>
            {card.cardBrand + " "}
            {verbose ? "ending in " : null}
            {card.cardLastFour}
            {card.isExpired ? " (Expired)" : null}
        </p>
    </>
);
