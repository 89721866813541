import { type StrictlyBasisPoints, add, max, stringFromBasisPoints } from "utils/currency";
import { Table, Typography } from "antd";
import { VyneTable, type VyneTableProps } from "components/vyneTable/VyneTable";
import { usePayment } from "components/Payment/usePaymentContext";
import { useStripeInitialContext } from "components/Payment/ProcessingPaymentFlow/Stripe/useStripeInitialContext";
import type { ThanksProps } from ".";

type TableColumns = VyneTableProps<Record<PropertyKey, unknown>>["columns"];

const SummaryColumns: TableColumns = [
    {
        title: "Item",
        dataIndex: "name",
    },
    {
        title: "Amount",
        dataIndex: "amount",
        width: "30%",
        align: "right",
    },
];

/* Latter portion that we now show for Patient Portal only */
export const ThanksPatientPortalBody = ({ amount }: ThanksProps) => {
    const { prepared } = usePayment();

    const totalBalance = useTotalBalance();
    const amountPaid = (amount ?? 0) as StrictlyBasisPoints;
    const rawRemaining = add(totalBalance, amountPaid);

    // translate a negative remaining balance to zero
    // in non-integrated cases, accounts for the request transaction we know was synthesized
    // in integrated cases (where the default and original balance comes from the PMS),
    // avoids showing a negative estimated remaining due
    const remaining = max(rawRemaining, 0);

    const dataSource = [
        {
            key: "Original_Balance",
            name: "Original Balance",
            amount: stringFromBasisPoints(totalBalance),
        },
        {
            key: "Amount_Paid",
            name: "Amount Paid",
            amount: stringFromBasisPoints(amountPaid),
        },
    ];

    return (
        <VyneTable
            name="receipt"
            className="vyne-thanks-summary"
            title={() => TableTitle(prepared.statementDate)}
            columns={SummaryColumns}
            dataSource={dataSource}
            pagination={false}
            summary={() => TableSummary(remaining)}
        />
    );
};

const TableTitle = (date: string) => (
    <Typography.Title level={3} className="vyne-thanks-summary-title">
        Payment Summary: {date}
    </Typography.Title>
);

const TableSummary = (remaining: StrictlyBasisPoints) => (
    <Table.Summary>
        <Table.Summary.Row>
            <Table.Summary.Cell index={0}>Remaining Balance</Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
                {stringFromBasisPoints(remaining)}
            </Table.Summary.Cell>
        </Table.Summary.Row>
    </Table.Summary>
);

function useTotalBalance() {
    const { prepared } = usePayment();
    const { intentId, balance } = useStripeInitialContext();

    if (intentId && balance) {
        // We've been redirected by Stripe, so we don't know whether prepared sale balance is before or after the
        // payment intent has been processed--we're racing the webhook and we don't know who won. Therefore, use balance
        // passed in by Stripe redirect.
        return balance;
    } else {
        return prepared.defaultBalance;
    }
}
