import "./selectCard.scss";
import { Button, Result, Skeleton } from "antd";
import { CardSummary } from "./CardSummary";
import { Checkbox, Flex, type GetProp, Radio } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { PayButton } from "../PayButton";
import { VyneInputRadio } from "components/vyneInputs/vyneInputRadio/VyneInputRadio";
import { VyneModal } from "components/vyneModal/VyneModal";
import { VyneSelect } from "components/vyneInputs/vyneSelect/VyneSelect";
import { useDeleteCard } from "api/clients/useDeleteCard";
import { useGetPatientCards } from "api/clients/useGetPatientCards";
import { usePayment } from "../../usePaymentContext";
import { useState } from "react";
import type { CardOnFile, TerminalSummaryResult } from "api/responses";
import type { PayButtonMode } from "../payButtonMode";
import type { SavedCardEvent, SavedCardState } from "./props";

export interface SharedCardOnFileProps {
    /** Text to show within submit button (and enablement of submit button) */
    payMode: PayButtonMode;

    /** Current state of saved card activity */
    state: SavedCardState;

    /** Dispatcher to update saved card state */
    dispatch: React.Dispatch<SavedCardEvent>;
}

const noTerminals: readonly TerminalSummaryResult[] = Object.freeze([]);

/**
 * Shared set of controls for managing a saved card, and for choosing a terminal (reader) when card-present payment is
 * desired.
 */
export const SharedSavedCardControls = (props: SharedCardOnFileProps) => {
    const { patientId, prepared } = usePayment();
    const { isPending, error, data: cards } = useGetPatientCards(patientId);

    if (isPending) {
        return <Skeleton.Button active />;
    }

    const terminals = prepared.terminalSummaries ?? noTerminals;
    const somethingOnFileAvailable = !error && (cards.length > 0 || terminals.length > 0);

    return somethingOnFileAvailable ? (
        <SavedCardAvailable {...props} cards={cards} terminals={terminals} />
    ) : (
        <SaveCard {...props} />
    );
};

interface CardAvailableProps extends SharedCardOnFileProps {
    cards: CardOnFile[];
    terminals: readonly TerminalSummaryResult[];
}

type Selection = "SavedCard" | "NewCard" | "SendToTerminal";

type RadioOnChangeProps = GetProp<typeof Radio, "onChange">;

const SavedCardAvailable = (props: CardAvailableProps) => {
    const { state, dispatch, cards, terminals } = props;
    const value: Selection = state.cardOnFileId ? "SavedCard" : state.terminalId ? "SendToTerminal" : "NewCard";
    const somethingOnFileSelected = value !== "NewCard";

    const onChange: RadioOnChangeProps = (e) => {
        const newValue = e.target.value as Selection;
        switch (newValue) {
            case "SavedCard":
                if (cards[0]) {
                    // select first card in the list that is not expired
                    const card = cards.find((c) => !c.isExpired) ?? cards[0];

                    dispatch({ type: "select-saved-card", cardOnFileId: card.id });
                }
                break;
            case "NewCard":
                dispatch({ type: "select-saved-card", cardOnFileId: null });
                break;
            case "SendToTerminal":
                if (terminals[0]) {
                    dispatch({ type: "select-terminal", terminalId: terminals[0].id });
                } else {
                    // Should never happen
                    console.warn("Tried to select terminal when there are no terminals available!");
                }
        }
    };

    return (
        <Flex className="select-payment-method" vertical={true} gap="1rem">
            <Radio.Group onChange={onChange} value={value}>
                {cards.length > 0 && (
                    <VyneInputRadio
                        value="SavedCard"
                        removeDetails={value !== "SavedCard"}
                        details={<SelectSavedCards {...props} cards={cards} />}
                    >
                        Saved Card
                    </VyneInputRadio>
                )}
                <VyneInputRadio value="NewCard">New Card</VyneInputRadio>
                {terminals.length > 0 && (
                    <VyneInputRadio
                        value="SendToTerminal"
                        removeDetails={value !== "SendToTerminal"}
                        details={<Terminals {...props} terminals={terminals} />}
                    >
                        Send to Terminal
                    </VyneInputRadio>
                )}
                <SaveCard {...props} />
            </Radio.Group>
            {somethingOnFileSelected && <SomethingOnFileSubmit {...props} />}
        </Flex>
    );
};

const SelectSavedCards = ({ cards, state, dispatch }: CardAvailableProps) => {
    const selectedCardId = state.cardOnFileId;

    if (cards.length === 0 || !selectedCardId) {
        throw new Error("Saved Cards component requires card information.");
    }

    const selectedCard = cards.find((card) => card.id === selectedCardId);

    const options = cards.map((card) => ({
        label: `${card.cardBrand} ${card.cardLastFour}`,
        value: card.id,
        title: `${card.cardBrand} ${card.cardLastFour}`,
    }));

    return (
        <Flex vertical={true} gap="0.25rem">
            <Flex align="center" gap="0.5rem">
                <VyneSelect
                    aria-label="Select Card"
                    id="select-saved-card"
                    onChange={(value) => dispatch({ type: "select-saved-card", cardOnFileId: value })}
                    options={options}
                    value={selectedCardId}
                    placeholder={<CardSummary placeholder={true} />}
                    labelRender={(label) => {
                        const card = cards.find((c) => c.id === label.value);
                        return card ? <CardSummary card={card} /> : null;
                    }}
                    optionRender={(options) => {
                        const card = cards.find((c) => c.id === options.value);
                        return card ? <CardSummary card={card} /> : null;
                    }}
                />
                <DeleteCard cardId={selectedCardId} cards={cards} dispatch={dispatch} />
            </Flex>
            {selectedCard?.isExpired ? (
                <aside className="card-expired">This card is expired. Please use a different card.</aside>
            ) : null}
        </Flex>
    );
};

const Terminals = ({ state, dispatch, terminals }: CardAvailableProps) => {
    const value = state.terminalId;

    if (!value) {
        throw new Error("Terminals component requires terminalId");
    }

    const options = terminals.map((terminal) => ({
        label: terminal.label,
        value: terminal.id,
        title: terminal.label,
    }));

    return (
        <VyneSelect
            aria-label="Select Terminal"
            id="select-terminal"
            onChange={(value) => dispatch({ type: "select-terminal", terminalId: value })}
            options={options}
            defaultValue={value}
            placeholder="Select A Terminal"
        />
    );
};

const SaveCard = ({ state, dispatch }: SharedCardOnFileProps) => {
    const cardOnFileSet = !!state.cardOnFileId;

    return (
        <Checkbox
            checked={state.saveCard || cardOnFileSet}
            disabled={cardOnFileSet}
            onChange={(e) => dispatch({ type: "set-save-card", saveCard: e.target.checked })}
            style={{ marginBottom: "1ex" }}
        >
            Save card for future use
        </Checkbox>
    );
};

const SomethingOnFileSubmit = ({ state, cards, payMode, dispatch }: CardAvailableProps) => {
    const { amountState } = usePayment();

    const onSubmit = () => {
        if (amountState.validatedAmount) {
            dispatch({ type: "submit-using-saved", amount: amountState.validatedAmount });
        }
    };

    const card = cards.find((card) => card.id === state.cardOnFileId);
    const finalEnabled = payMode.enabled && !card?.isExpired;

    return (
        <PayButton
            id="SavedCardControlsSubmitButton"
            payMode={{ ...payMode, enabled: finalEnabled }}
            onSubmit={onSubmit}
        />
    );
};

interface DeleteCardProps {
    cardId: number;
    cards: CardOnFile[];
    dispatch: React.Dispatch<SavedCardEvent>;
}

const DeleteCard = ({ cardId, cards, dispatch }: DeleteCardProps) => {
    const { patientId } = usePayment();

    const [modalVisible, setModalVisible] = useState(false);

    const { isPending, isError, mutate } = useDeleteCard({
        onSuccess: (_, { cardId: deletedCardId }) => {
            // card deleted successfully
            // get first card in the list that is not the deleted card, or null if no cards left
            const newCard = cards.find((card) => card.id !== deletedCardId)?.id ?? null;
            dispatch({ type: "select-saved-card", cardOnFileId: newCard });

            // close modal
            setModalVisible(false);
        },
    });

    const selectedCard = cards.find((card) => card.id === cardId);

    if (!selectedCard) {
        return null;
    }

    return (
        <>
            <Button className="delete-card" danger type="link" onClick={() => setModalVisible(true)}>
                Delete Card
            </Button>
            <VyneModal
                className="vyne-delete-modal"
                confirmLoading={false}
                title="Delete Card"
                open={modalVisible}
                okButtonProps={{
                    danger: true,
                    disabled: isPending,
                    loading: isPending,
                }}
                okText="Delete"
                onOk={() => mutate({ patientId, cardId })}
                cancelButtonProps={{ loading: isPending }}
                cancelText="Cancel"
                onCancel={() => setModalVisible(false)}
            >
                {isError ? (
                    <Result
                        title="Unable to delete card"
                        subTitle="There was a problem deleting the card. Contact support if this error persists."
                    />
                ) : (
                    <DeleteCardContent selectedCard={selectedCard} />
                )}
            </VyneModal>
        </>
    );
};

const DeleteCardContent = ({ selectedCard }: { selectedCard: CardOnFile }) => (
    <>
        <ExclamationCircleFilled className="delete-exclamation-icon" />
        <h2>Are you sure?</h2>
        <div>
            Are you sure you want to delete the card &quot;
            <CardSummary card={selectedCard} />
            &quot;?
        </div>
    </>
);
